header {
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    color: white;
    /* margin-bottom: 20px; */
}

.header-branding, .header-rest {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-rest {
    flex-grow: 1;
}

header a:not(.header-vertical-item), header a:is(:visited, :active):not(.header-vertical-item) {
    color: white;
    text-decoration: underline transparent 1px;
    text-underline-offset: 5px;
}

header a.header-vertical-item, header a.header-vertical-item:is(:visited, :active) {
    color: white;
    text-underline-offset: 4px;
}

header a.header-vertical-item:not(.active), header a.header-vertical-item:is(:visited, :active):not(.active) {
    text-decoration: none;
}


header a:hover {
    text-decoration: underline white 2px;
}

.header-title {
    font-size: 50px;
    margin-left: 20px;
    font-family: "Outfit";
}

.header-logo {
    object-fit: contain;
    object-position: center;
    width: 80px;
    height: 80px;
    padding: 10px;

}

.header-content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-inline: 40px;
    font-family: 'Playwrite IT Moderna';
}

.header-item {
    position: relative;
    font-size: 20px;
    
}

.header-item:not(.active) .header-active-indicator {
    display: none;
}

.header-item.active .header-active-indicator {
    position: absolute;
    bottom: -25px;
    left: 50%;
    /* left: 0px; */
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    translate: -50% -50%;
    /* transition: left 0.5s; */
}

.header-vertical-item {
    font-size: 10px;
}

.header-vertical-items-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: max-content;

    margin-right: 60px;
}

