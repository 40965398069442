
.fancy-picker {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    font-weight: 500;
    font-size: 40px;
    --options-offset: 0px;
    --picker-top-padding: 0px;
    margin-top: var(--picker-top-padding);
    width: fit-content;
    padding-block: 20px;
}

.fancy-picker .options-container {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    top: var(--options-offset);
    transition: top 0.5s;

}

.fancy-option.selected {
    color: black;
    transition: color 0.5s;
}

.fancy-option {
    appearance: none;
    border: none;
    background: none;
    font: inherit;
    /* text-decoration: underline 4px; */
    text-decoration-line: underline;
    text-decoration-thickness: 4px;
    font-size: 1em;
}


.fancy-option:not(.selected) {
    color: var(--mid-grey);
    /* text-decoration: underline var(--mid-grey); */
    text-decoration-color: var(--mid-grey);
    cursor: pointer;
}