@media (max-width: 900px) {

        /* HEADER */

        #root header {
            /* overflow: scroll; */
            flex-direction: column;
        }

        #root .header-rest {
            padding-bottom: 40px;
            justify-content: center;
        }

        #root .header-content {
            gap: 40px;
        }

        #root .header-rest .grow-spacer {
            display: none;
        }


        /* OTHER GAMES */

        #root .other-games-box-container {
            position: relative;
            top: -1px;
            flex-direction: column;
            align-items: center;
        }


        #root .other-games-box {
            align-items: flex-start;
        }

        #root .header-vertical-items-container {
            margin-right: 0;
        }


        /* BOARD */

        #root .team-guess-board *:is(.player-team-icon, .player-silhouette) {
            width: 60px;
            height: 60px;
        }

        #root .guess-board {
            font-size: 12px;
        }

        /* ADS */
        #root .ads-around .ads.col {
            display: none;
        }

        #root .ads-around{
            justify-content: center;
        }

}

@media (max-width: 550px) {
        /* HEADER */

        #root .header-branding {
            width: calc(100% - 70px);
        }

        #root .header-logo {
            width: 50px;
            height: 50px;
            padding-inline: 0;
            margin-left: 20px;
        }

        #root .header-title {
            font-size: 30px;
            text-align: center;
            margin-left: 0px;
            flex-grow: 1;
        }
        
        /* TUTORIAL */

        #root .tutorial-popover {
            zoom: 0.7;
        }

        /* OTHER GAMES */

        #root .other-games-box-container {
            padding: 0;
            max-width: 100vw;
            overflow-x: scroll;
        }

        #root .other-games-box {
            padding: 10px;
        }

}

@media (max-width: 400px) {
        /* HEADER */

        #root .header-title {
            font-size: 26px;
        }

        /* OTHER GAMES */
        
        #root .other-game-img {
            width: 20px;
            height: 20px;
        }
        
        /* BOARD */

        #root .guess-board {
            gap: 0px;
            width: 100%;
            justify-content: space-evenly;
        }
}
