@font-face {
    font-family: 'Playwrite IT Moderna';
    src: url('../res/fonts/Playwrite_IT_Moderna/PlaywriteITModerna-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Playwrite IT Moderna';
    src: url('../res/fonts/Playwrite_IT_Moderna/static/PlaywriteITModerna-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Playwrite IT Moderna';
    src: url('../res/fonts/Playwrite_IT_Moderna/static/PlaywriteITModerna-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Playwrite IT Moderna';
    src: url('../res/fonts/Playwrite_IT_Moderna/static/PlaywriteITModerna-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Playwrite IT Moderna';
    src: url('../res/fonts/Playwrite_IT_Moderna/static/PlaywriteITModerna-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/Outfit-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/static/Outfit-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/static/Outfit-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/static/Outfit-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/static/Outfit-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/static/Outfit-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/static/Outfit-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/static/Outfit-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/static/Outfit-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit';
    src: url('../res/fonts/Outfit/static/Outfit-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}