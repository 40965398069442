/**************************/
.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
}

.autocomplete-items>div:not(.autocomplete-active) {
    color: black;
}

/* .autocomplete-active {
    color: black;
    background-color: #e9e9e9;
} */

input {
    border: 1px solid transparent;
    font-size: 16px;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:is(:hover, .autocomplete-active) {
    /*when hovering an item:*/
    background-color: #e9e9e9;
}