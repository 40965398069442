footer {
    background-color: var(--primary);
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-block: 40px;
    min-height: 160px;
    font-family: "Outfit";
    /* font-family: 'Playwrite IT Moderna'; */
}

footer a, footer a:is(:visited, :active) {
    color: white;
    text-decoration: underline white 1px;
    text-underline-offset: 4px;
}