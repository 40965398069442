.guesser {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}

.guesser-input {
    background-color: var(--light-grey);
    /* width: 10px; */
    padding: 10px;
    border-radius: 5px;
    width: 180px;
}

.guesser-input::placeholder {
    text-align: center;
}

.guesser-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


.guess-button {
    appearance: none;
    border: none;
    padding: 10px 20px;
    background-color: var(--light-grey);
    border-radius: 5px;
}



.guess-button:not(.disabled) {
    cursor: pointer;
    background-color: var(--primary);
    color: white;
}

.guess-button:not(.disabled):hover {
    background-color: rgb(18, 103, 18);
}

.guess-button.disabled {
    color: gray;
}

.guess-history-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.x-guess {
    object-fit: contain;
    object-position: center;
    width: 16px;
}

.guess-history-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}