.player-silhouette {
    object-fit: contain;
    object-position: center;
    width: 100px;
    height: 100px;
}

.hide-silhouette, .hide-mystery-ball {
    opacity: 0;
}

.dark-img {
    filter: brightness(0);
    -webkit-filter: brightness(0);
}

.guess-board {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    font-size: 16px;
}

.team-guess-board {
    flex-direction: row;
    justify-content: center;
}

.player-guess-board {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.player-guess-board {
    zoom: 1.4;
}

.reveal-button {
    font: inherit;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    background-color: var(--primary-pale);
    cursor: pointer;
}


.player-team-icon {
    object-fit: contain;
    object-position: center;
    width: 100px;
    height: 100px;
}

.player-team-history {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-history-arrow-up {
    object-fit: contain;
    object-position: center;
    width: 20px;
    /* height: 100px; */
}

.player-mystery-container .player-silhouette {
    position: absolute;
    z-index: -1;
    
}

.team-icon-container, .team-icon-and-label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-icon-container:not(:first-child)>.team-icon-and-label {
    margin-block: 10px;
    /* background-color: var(--light-grey); */
    border-radius: 10px;
}

.team-year-label {
    margin-bottom: 10px;
}

.more-teams-label {
    /* background-color: var(--light-grey); */
    border-radius: 50%;
    /* width: 30px;
    height: 30px; */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-hints-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}