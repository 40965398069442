.game-cycle-display {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: white;
    width: fit-content;
    padding: 20px;
    border-radius: 10px;
}

.game-cycle-display.win {
    background-color: var(--primary);
}

.game-cycle-display.lose {
    /* background-color: var(--secondary); */
    /* background-color: rgb(168, 4, 4); */
    background-color: #B03A2E;
}

.game-cycle-body {
    /* margin-bottom: 20px; */
}


.new-game-button, .share-score-button {
    appearance: none;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    width: fit-content;
    background-color: var(--light-grey);
    font-size: 15px;
    cursor: pointer;
    color: black;
}

.tertiary-bg {
    background-color: var(--tertiary);

}

.share-score-button {
    margin-bottom: 20px;
    /* background-color: var(--secondary);
    color: white; */
}

.new-game-button:hover, .share-score-button:hover{
    background-color: white;
}

.new-other-game-button {
    color: black;
}

.new-other-game-button:hover {
    background-color: #f9de8b;
}

.new-game-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.disabled-game-button, .disabled-game-button:hover {
    background: none;
    color: var(--ter);
    border: 2px solid white;
    cursor: default;
}

a.new-game-button, a.new-game-button :is(:visited, :active) {
    color: black;
    text-decoration: none;
}

.new-other-page-game-button {
    margin-top: 20px;
}