.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 40px;
    font-family: 'Outfit';
    min-height: 60vh;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    font-family: 'Outfit';
    align-items: flex-start;
}

p.hidden {
    visibility: hidden;
    max-height: 0;

}

.contact-form label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.contact-form :is(input, textarea, select) {
    background-color: var(--primary-pale);
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'Outfit';
    border: 1px solid var(--primary);
}

.contact-submit {
    appearance: none;
    padding: 4px 20px;
    border-radius: 4px;
    font-family: 'Outfit';
    font-size: 20px;
    font-weight: 600;
    background-color: var(--primary);
    border: 1px solid var(--primary-pale);
    color: white;
    cursor: pointer;
}