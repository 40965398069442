.tutorial-button {
    position: absolute;
    right: 20px;
    appearance: none;
    border: none;
    height: 50px;
    min-width: 50px;
    border-radius: 5px;
    width: fit-content;
    background-color: var(--glass-white);
    color: white;
    font-weight: 600;
    font-size: 25px;
    cursor: pointer;
    font-family: 'Outfit';
    z-index: 3;
}

.tutorial-popover-close {
    appearance: none;
    border: none;
    height: 50px;
    min-width: 50px;
    border-radius: 5px;
    width: fit-content;
    background-color: var(--glass-white);
    color: white;
    font-weight: 600;
    font-size: 25px;
    cursor: pointer;
    font-family: 'Outfit';
}


.tutorial-button:hover {
    background-color: var(--glass-white-hover);
}

.tutorial-popover-container {
    position: relative;
    padding-top: 100px;
    inset: 0;
    /* background-color: #ff0000a0; */
    /* backdrop-filter: blur(4px) brightness(0.8); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.tutorial-popover {
    font-family: 'Outfit';
    background-color: var(--primary);
    color: white;
    width: 500px;
    min-height: 400px;
    /* height: 55vh; */
    /* max-height: 55vh; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: flex-start;
}

.tutorial-popover-body {
    display: flex;
    height: fit-content;
    /* max-height: 460px; */
    overflow: scroll;
}

.tutorial-popover-header {
    background-color: var(--primary);
    border-bottom: 2px solid var(--primary-pale);
    color: white;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.tutorial-popover-title {
    font-size: 40px;
    margin-left: 20px;
    font-family: "Outfit";
}

.tutorial-section {
    width: 100%;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
}

.tutorial-img {
    object-fit: contain;
    object-position: center;
    border-radius: 10px;
    align-self: center;
}

.tutorial-guess-button {
    height: 100px;
}

.Player-tutorial *:is(.tutorial-first-guess, .tutorial-final-guess) {
    width: 150px;
}

.Team-tutorial *:is(.tutorial-first-guess, .tutorial-final-guess) {
    width: 400px;
}

.tutorial-popover-footer {
    padding: 30px 40px 30px 40px;
    background-color: var(--primary);
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.tutorial-footer-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}

.tutorial-footer-button {
    appearance: none;
    border: none;
    height: 50px;
    /* min-width: 50px;
    width: max-content; */
    padding-inline: 16px;
    border-radius: 5px;
    width: fit-content;
    background-color: var(--glass-white);
    color: white;
    font-weight: 600;
    font-size: 25px;
    cursor: pointer;
    font-family: 'Outfit';
}

.tutorial-footer-button:hover {
    background-color: var(--glass-white-hover);
}

.tutorial-footer-button.prev:disabled {
    opacity: 0;
    pointer-events: none;
}

.tutorial-footer-button.next:disabled {
    display: none;
}

.tutorial-footer-button:not(:disabled)+.tutorial-footer-button.play {
    display: none;
}

.tutorial-footer-button:not(:disabled)+.tutorial-footer-button.play {
    display: none;
}

.tutorial-footer-button:disabled+.tutorial-footer-button.play {
    display: block;
}


.tutorial-popover-dots {
    height: 6px;
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
}

.tutorial-popover-dot {
    width: 6px;
    height: 6px;
    background-color: var(--glass-white-hover);
    border-radius: 50%;
}

.tutorial-popover-dot.active {
    background-color: white;
}