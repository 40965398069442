.daily-counter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    background-color: var(--primary);
    color: white;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    gap: 10px;
    min-width: 160px;
}

.daily-day {
    font-size: 40px;
    font-family: 'Playwrite IT Moderna';
    margin: 0;
}