.other-games-box-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    background-color: var(--primary);
    padding: 10px;
    padding-top: 0;
    border-radius: 0 0 10px 10px;
    margin-bottom: 20px;
}

footer .other-games-box-container {
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
}

.other-games-box {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* margin-top: 20px; */
    align-items: center;
    justify-content: center;
    
    /* border-top: 4px solid white; */
}

footer .other-games-box {
    margin-top: 20px;
}

.other-games-label {
    content: "Play other sports";
    font-size: 20px;
    font-weight: bold;
    min-width: max-content;
    /* color: var(--primary-pale); */
    color: white;
    /* margin-right: 20px; */
}

.other-game-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-decoration: none;
}

.other-game-img {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    object-fit: contain;
    background-color: rgb(0, 0, 0);
}

.other-game-label {
    /* display: none; */
    max-width: 0px;
    max-height: 0px;
    opacity: 0;
    font-size: 10px;
    text-align: center;
    overflow: hidden;
    transition: max-width 0.5s ease, max-height 0.5s ease, opacity 0.2s ease;
}

.other-games-box-container:hover .other-game-label {
    max-width: 200px;
    max-height: 60px;
    opacity: 1;
}

/* detailed list */

/* .other-games-detailed-pre-title {
} */

.other-games-detailed-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 140px);
    width: 500px;
    max-width: calc(100vw - 40px);
    justify-content: space-evenly;
    /* align-items: flex-start; */

    gap: 20px;
    background-color: var(--primary);
    padding: 20px;
    border-radius: 10px;
    margin-block: 20px;
}

.other-game-detailed-item {
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background-color: var(--glass-white-hover);
    border-radius: 10px;
    height: 235px;
}

.other-game-detailed-img {
    width: 80px;
    border-radius: 8px;
}

.other-game-detailed-name {
    text-align: center;
    margin-bottom: 10px;
}

.other-game-detailed-details {
    /* width: min-content; */
    font-size: 12px;
}

.other-game-detailed-name *:is(a, a:visited) {
    color: white;
}