.ads {
    /* background-color: red; */
    position: relative;
}

.ads.row {
    width: 100%;
    height: 100px;
}

.ads.col {
    width: 100%;
    max-width: 400px !important;
    height: 100px;
    overflow: hidden !important;
}

div.IL_IF {
    top: 190px !important;
}