* {
    margin: 0;
    padding: 0;
}

:root {
    --primary: #144631;
    --primary-pale: rgb(194, 228, 209);
    --secondary: #B56A1D;
    --tertiary: #F0C957;
    --light-grey: #EEE;
    --glass-white: #ffffff20;
    --glass-white-hover: #ffffff40;
    /* --light-grey: #DDD; */
    --mid-grey: #AAA;


}

.game-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App {
    position: relative;
    font-family: 'Outfit';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start !important;
}

body {
    min-height: 100vh;
}

.vert-cent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fancy-text-shadow {
    text-shadow: var(--secondary) 1px 1px, var(--secondary) 2px 2px, var(--secondary) 3px 3px, var(--secondary) 4px 4px;
}

/* .fancy-text-shadow-white {
    text-shadow: white 1px 1px, white 2px 2px, white 3px 3px, white 4px 4px;
} */

.fancy-text-shadow-small {
    text-shadow: var(--secondary) 1px 1px, var(--secondary) 2px 2px, var(--secondary) 3px 3px;
}

.fancy-text-shadow-tiny {
    text-shadow: var(--secondary) 1px 1px, var(--secondary) 2px 2px;
}

/* .fancy-drop-shadow {
    filter: drop-shadow(2px 2px var(--secondary));
} */


img {
    user-select: none;
}

.hide-game {
    display: none;
}

.grow-spacer {
    flex-grow: 1;
}

.ads-around {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.data-warning {
    text-align: center;
}


